@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;
}

.priceLabel {
  position: relative;
  cursor: pointer;

  & svg {
    width: 36px;
    height: 36px;
    fill: none;

    @media (--viewportMedium) {
      width: 48px;
      height: 48px;
    }
  }
}

.priceLabelActive {
  /* Same as hover */
  transform: translateY(-10px);
}
